import React from "react"
import styled from "styled-components"
import { breakpoints, colors, fontSizes, measure, media } from "../styles"
import { VscQuote } from "react-icons/vsc"

const Root = styled.div`
  font-family: "Libre Baskerville";
  color: ${colors.gray400};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${measure.measure32} 0;
  position: relative;
  text-align: center;

  ${media.smallerThan(breakpoints.large)} {
    padding: ${measure.measure48} ${measure.measure24};
  }

  ${media.smallerThan(breakpoints.medium)} {
    margin-left: 0;
    padding: ${measure.measure48} ${measure.measure24};
  }
`

const QuoteIcon = styled(VscQuote)`
  position: absolute;
  color: ${colors.gray500};
  opacity: 0.1;

  font-size: 96px;
  left: -24px;
  top: -24px;

  ${media.smallerThan(breakpoints.large)} {
    left: 16px;
    top: -8px;
  }

  ${media.smallerThan(breakpoints.medium)} {
    font-size: 72px;
    left: 24px;
    top: 8px;
  }
`

const Primary = styled.div`
  font-size: ${fontSizes.size30};

  ${media.smallerThan(breakpoints.medium)} {
    font-size: ${fontSizes.size24};
  }
`

const Secondary = styled.div`
  font-size: ${fontSizes.size24};
  margin-top: ${measure.measure16};
  margin-left: ${measure.measure16};

  ${media.smallerThan(breakpoints.medium)} {
    font-size: ${fontSizes.size18};
    margin-top: ${measure.measure12};
    margin-left: ${measure.measure8};
  }
`

type Props = {
  text: React.ReactNode
  subtext?: React.ReactNode
}
export const Quote = ({ text, subtext }: Props) => (
  <Root>
    <QuoteIcon />
    <Primary>{text}</Primary>
    {subtext && <Secondary>{subtext}</Secondary>}
  </Root>
)
