import React from "react"
import styled, { css } from "styled-components"
import { breakpoints, measure, media } from "../styles"
import { ContentContainer } from "./ContentContainer"

interface Props {
  maxWidth?: "medium" | "wide"
  verticalSeparation?: "medium" | "big"
  children: React.ReactNode
}

const BlockContainer = styled.div<Pick<Props, "verticalSeparation">>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > * + * {
    margin-top: ${measure.measure128};

    ${media.smallerThan(breakpoints.large)} {
      margin-top: 128px;
    }

    ${media.smallerThan(breakpoints.large)} {
      margin-top: 64px;
    }

    ${({ verticalSeparation }) =>
      verticalSeparation === "big" &&
      css`
        ${media.large} {
          margin-top: 128px;
        }
      `}
  }
`

export const Section = ({
  maxWidth = "wide",
  verticalSeparation = "big",
  children,
}: Props) => {
  return (
    <ContentContainer horizontalPadding={true} maxWidth={maxWidth}>
      <BlockContainer verticalSeparation={verticalSeparation}>
        {children}
      </BlockContainer>
    </ContentContainer>
  )
}
