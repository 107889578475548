import React, { useState } from "react"
import styled, { css } from "styled-components"
import { ContentContainer } from "../components/ContentContainer"
import { breakpoints, colors, fontSizes, measure, media } from "../styles"
import { useLanguage } from "../utils/useLanguage"
import { VscQuote } from "react-icons/vsc"
import { Fade } from "react-awesome-reveal"

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: ${colors.gray500};
  font-family: "Libre Baskerville";

  margin-left: ${measure.measure24};
  margin-right: ${measure.measure24};

  height: 400px;

  ${media.smallerThan(breakpoints.medium)} {
    height: 440px;
  }
`

const Content = styled.div`
  position: relative;
`

const QuoteIcon = styled(VscQuote)`
  color: ${colors.gray500};
  opacity: 0.1;
  position: absolute;

  font-size: 96px;
  left: -16px;
  top: -64px;

  ${media.smallerThan(breakpoints.large)} {
    left: -8px;
    top: -64px;
  }

  ${media.smallerThan(breakpoints.medium)} {
    font-size: 72px;
    left: 24px;
    top: -48px;
  }
`

const Quote = styled.div`
  max-width: ${measure.measure640};
  color: ${colors.gray500};
  margin-bottom: ${measure.measure32};
  font-size: ${fontSizes.size24};

  ${media.smallerThan(breakpoints.medium)} {
    font-size: ${fontSizes.size20};
  }
`

const Author = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${measure.measure4};
`

const Name = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${colors.gray700};

  ${media.smallerThan(breakpoints.medium)} {
    font-size: ${fontSizes.size16};
  }
`

const Role = styled.div`
  font-size: 16px;
  color: ${colors.gray500};

  ${media.smallerThan(breakpoints.medium)} {
    font-size: ${fontSizes.size14};
  }
`

const DotNavigator = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${measure.measure16};
`

const DotContainer = styled.div`
  padding: ${measure.measure8};
  &:hover {
    cursor: pointer;
  }
`

const Dot = styled.div<{ active: boolean }>`
  border-radius: 25px;
  height: ${measure.measure12};
  width: ${measure.measure12};
  border: 1px solid ${colors.gray400};

  ${({ active }) =>
    active &&
    css`
      background-color: ${colors.gray500};
      border-color: ${colors.gray500};
    `}
`

export const Testimonials = () => {
  const language = useLanguage()
  const testimonials = i18n[language]

  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <ContentContainer maxWidth="wide">
      <Root>
        <Fade key={activeIndex}>
          <Content>
            <QuoteIcon />
            <Quote>{testimonials[activeIndex].quote}</Quote>
            <Author>
              <Name>{testimonials[activeIndex].name}</Name>
              <Role>{testimonials[activeIndex].role}</Role>
            </Author>
          </Content>
        </Fade>
        <DotNavigator>
          {testimonials.map((_, index) => (
            <DotContainer key={index} onClick={() => setActiveIndex(index)}>
              <Dot active={index === activeIndex} />
            </DotContainer>
          ))}
        </DotNavigator>
      </Root>
    </ContentContainer>
  )
}

const i18n = {
  es: [
    {
      quote: `Nuestros alumnos internacionales de español para extranjeros disfrutaron 
        de un espectáculo inolvidable en versión bilingüe con Ana y Gonzalo.
        Historias mágicas, diversión y complicidad entre los actores y un público entusiasmado."`,
      name: "Luisa Rodríguez Sordo",
      role: (
        <>
          Jefa del departamento de español <br />
          Escuela Oficial de Idiomas - Las Rozas
        </>
      ),
    },
    {
      quote: `Inquietos alumnos de bachillerato, hipnotizados por unas historias llenas de filosofía y humor, contadas con sensibilidad y talento. 
        Un viaje de aventura al corazón de los espectadores."`,
      name: "Susana Aguirrizabal",
      role: (
        <>
          Profesora de inglés <br />
          IES Don Pelayo-Los Hueros - Madrid
        </>
      ),
    },
    {
      quote: `Una sala llena hasta rebosar de alumnos de inglés y de español para extranjeros, que se vino abajo de carcajadas, complicidad e ingenio. 
        Una actuación brillante y cercana, en la que nos sentimos protagonistas. Ana y Gonzalo, volved pronto con vuestras historias en versión bilingüe!"`,
      name: "Arturo Rodríguez López",
      role: (
        <>
          Profesor de español para extranjeros <br />
          Escuela Oficial de Idiomas - Alcalá de Henares
        </>
      ),
    },
    {
      quote: `Fascinante sesión de historias, en la que varios alumnos pusieron a prueba su dominio del inglés y su talento interpretativo.
        Grandes narradores que activan la participación."`,
      name: "Ramón Silles McLaney",
      role: (
        <>
          Profesor de inglés y director <br />
          Escuela Oficial de Idiomas - Boadilla
        </>
      ),
    },
  ],
  en: [
    {
      quote: `Our international students of Spanish enjoyed an unforgettable bilingual storytelling session with Ana and Gonzalo.
        Magic tales and lots of fun and complicity between the actors and the delighted audience."`,
      name: "Luisa Rodríguez Sordo",
      role: (
        <>
          Head teacher of Spanish <br />
          Escuela Oficial de Idiomas - Las Rozas
        </>
      ),
    },
    {
      quote: `Restless high-school students hypnotized by stories with philosophical thoughts and a good sense of humour. 
        Two storytellers that communicate with sensitivity and talent.
        An adventurous trip to the heart of the listeners."`,
      name: "Susana Aguirrizabal",
      role: (
        <>
          English teacher <br />
          IES Don Pelayo-Los Hueros - Madrid
        </>
      ),
    },
    {
      quote: `Our Spanish and English students filled the room with laughter, humour and complicity. 
        A sharp listener-centred performance.
        Ana and Gonzalo, please, come back soon with your bilingual stories!"`,
      name: "Arturo Rodríguez López",
      role: (
        <>
          Spanish teacher <br />
          Escuela Oficial de Idiomas - Alcalá de Henares
        </>
      ),
    },
    {
      quote: `A fascinating storytelling session where some students turned into actors and proved their command of the language and their talent for acting.
        Excellent storytellers that activate participation."`,
      name: "Ramón Silles McLaney",
      role: (
        <>
          English teacher and director <br />
          Escuela Oficial de Idiomas - Boadilla
        </>
      ),
    },
  ],
}
