export const i18n = {
  es: {
    magicOfStories: {
      title: "La magia del cuento",
      content: [
        "Ven a escuchar mis historias del mundo, y a soñar, aprender, reír y compartir.",
        "Con relatos que llegarán a tu corazón.",
        "Vive la experiencia de una sesión de cuentos en inglés, en español o en versión bilingüe, a dúo con el actor Gonzalo Palmero.",
        "En nuestro espectáculo, tú serás el protagonista de viajes inolvidables.",
      ],
    },
    whyTellStories: {
      title: "Por qué contar historias",
      content: [
        "Mi padre fue un narrador de sorprendente talento. Sus relatos de la Ilíada y la Odisea y las aventuras de aquellos personajes pronto llenaron mi cabeza de fantasía y emoción.",
        "Después, he combinado mi faceta de profesora de inglés con la de cuentacuentos, actuando en muestras de teatro y en Escuelas Oficiales de Idiomas e institutos.",
        "También he impartido sesiones de formación para profesores de idiomas, sobre el uso de las historias como herramienta de aprendizaje de lenguas.",
      ],
    },
    duoStorytelling: {
      title: "Historias bilingües a dúo",
      content1: [
        "Hace unos años se cruzó en mi camino Gonzalo Palmero, actor versátil y lleno de ingenio y humor.",
        "Y emprendimos la desafiante aventura de contar historias a dúo en versión bilingüe.",
        "Contar juntos es para nosotros un maravilloso viaje compartido.",
        "Nuestros relatos tienen color, como las tierras y culturas de las que proceden, y nos hablan de experiencias del mundo y de sus gentes.",
      ],
      content2: [
        "Recientemente hemos viajado con nuestras historias a las Escuelas Oficiales de Idiomas de Alcalá de Henares, Las Rozas, Valdezarza, y el IES Don Pelayo, en Los Hueros (Madrid).",
        "Si te apetece participar en nuestros relatos y soñar con nosotros, ven a conocernos.",
      ],
    },
    quotes: {
      women: {
        text: "¿Qué es lo que más desean las mujeres?”",
        subtext: "—preguntó el caballero.",
      },
      trip: "Un viaje de aventura al corazón de los espectadores”",
      stories: "Estamos hechos de historias”",
    },
  },
  en: {
    magicOfStories: {
      title: "The magic of stories",
      content: [
        "Come to listen to my tales from the world, and to dream, learn, laugh and share.",
        "With stories that will touch your heart.",
        "Live the experience of a storytelling session in English, Spanish or in a bilingual version with actor Gonzalo Palmero.",
        "You’ll travel to unforgettable lands.",
      ],
    },
    whyTellStories: {
      title: "Why tell stories",
      content: [
        "My father had an extraordinary gift for storytelling. His amazing tales and the adventures of the characters from the Iliad and Odyssey soon filled my childhood with fantasy and emotions.",
        "Later, I combined my work as an English teacher with storytelling performances in theatre events, language schools (Escuelas Oficiales de Idiomas) and high-schools.",
        "I’ve also been a teacher trainer with a programme about the use of stories as a learning tool in the language classroom.",
      ],
    },
    duoStorytelling: {
      title: "Duo bilingual storytelling",
      content1: [
        "Some years ago, Gonzalo Palmero crossed my path. He’s a witty and versatile actor with a great sense of humour.",
        "Then, we started the challenging adventure of telling stories in bilingual version.",
        "For us, duo storytelling is like sharing a trip.",
        "Our stories have colours, like the lands and cultures they come from, and they tell us about the world and its peoples.",
      ],
      content2: [
        "Lately we have travelled with our stories to the language schools EOI Alcalá de Henares, EOI Las Rozas, EOI Valdezarza and IES Don Pelayo in Los Hueros (Madrid).",
        "If you want to participate and to embark on incredible travels, come see us.",
      ],
    },
    quotes: {
      women: {
        text: "What is it that women desire most of all?”",
        subtext: "—asked the knight.",
      },
      trip: "An adventurous trip to the heart of the listeners”",
      stories: "We are made of stories”",
    },
  },
}
