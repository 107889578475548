import React from "react"
import styled from "styled-components"
import { PrimaryHero } from "../blocks/hero/primary/PrimaryHero"
import { Quote } from "../blocks/Quote"
import { Footer } from "../blocks/Footer"
import { graphql, useStaticQuery } from "gatsby"
import { Diptych } from "../components/Diptych"
import { Section } from "../components/Section"
import { Testimonials } from "../blocks/Testimonials"
import { PageCommon } from "../components/PageCommon"
import { ContactFormDiptych } from "../blocks/ContactFormDiptych"
import { BackToTopButton } from "../components/BackToTopButton"
import { i18n } from "../content/index/i18n"
import { useLanguage } from "../utils/useLanguage"
import { colors } from "../styles"
import { Gallery } from "../blocks/Gallery"
import { Subscription } from "../blocks/Subscription"

const Main = styled.main`
  & > div:nth-child(even) {
    background-color: ${colors.gray100};
  }
`

export default () => {
  const data = useStaticQuery(query)
  const language = useLanguage()
  const content = i18n[language]

  return (
    <>
      <PageCommon />
      <PrimaryHero />
      <Main>
        <Section>
          <Quote
            text={content.quotes.women.text}
            subtext={content.quotes.women.subtext}
          />
          <Diptych
            imageFluid={data.ana1.childImageSharp.fluid}
            imageHeightRatio={85}
            title={content.magicOfStories.title}
            content={
              <>
                {content.magicOfStories.content.map(text => (
                  <p key={text}>{text}</p>
                ))}
              </>
            }
          />
        </Section>
        <Section>
          <Quote text={content.quotes.trip} />
          <Diptych
            imageFluid={data.ana2.childImageSharp.fluid}
            imageHeightRatio={90}
            title={content.whyTellStories.title}
            reverseOrder={true}
            content={
              <>
                {content.whyTellStories.content.map(text => (
                  <p key={text}>{text}</p>
                ))}
              </>
            }
          />
        </Section>
        <Section>
        <Quote text={content.quotes.stories} />
          <Diptych
            imageFluid={data.anaGonzalo1.childImageSharp.fluid}
            imageHeightRatio={90}
            title={content.duoStorytelling.title}
            content={
              <>
                {content.duoStorytelling.content1.map(text => (
                  <p key={text}>{text}</p>
                ))}
              </>
            }
          />
          <Diptych
            imageFluid={data.anaGonzalo2.childImageSharp.fluid}
            imageHeightRatio={75}
            reverseOrder={true}
            content={
              <>
                {content.duoStorytelling.content2.map(text => (
                  <p key={text}>{text}</p>
                ))}
              </>
            }
          />
        </Section>
        <Gallery />
        <Testimonials />
        <Section>
          <ContactFormDiptych />
        </Section>
      </Main>
      <Footer />
      <BackToTopButton />
    </>
  )
}

export const query = graphql`
  query {
    ana1: file(relativePath: { eq: "stories/ana-1.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ana2: file(relativePath: { eq: "stories/ana-2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    anaGonzalo1: file(relativePath: { eq: "stories/ana-gonzalo-1.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    anaGonzalo2: file(relativePath: { eq: "stories/ana-gonzalo-2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    post: file(relativePath: { eq: "stories/post.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
