import React from "react"
import styled, { css } from "styled-components"
import { breakpoints, colors, measure, media } from "../../../styles"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { ScrollDownButton } from "../../../components/ScrollDownButton"
import { useLanguage } from "../../../utils/useLanguage"
import { Language } from "../../../types"
import { DesktopHeader } from "../header/DesktopHeader"
import { MobileHeader } from "../header/MobileHeader"
import { contentWidths } from "../../../styles/measure"

const i18n = {
  es: {
    title: ["Cuentacuentos", "Profesora de inglés", "Formadora de profesores"],
  },
  en: {
    title: ["Storyteller", "English teacher", "Teacher trainer"],
  },
}

const rootStyle = css`
  background-color: ${colors.backgroundStrong};
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DesktopRoot = styled.div`
  ${rootStyle}

  ${media.smallerThan(breakpoints.large)} {
    display: none;
  }
`

const DesktopMain = styled.div`
  width: 100%;
  max-width: ${contentWidths.wide};

  /**
   * Parent of Gatsby Image needs fixed height. 
   * (Desktop header height: 128px) 
   */
  height: calc(100vh - 128px);
  max-height: 682px;

  display: flex;
  align-items: center;

  padding-top: ${measure.measure24};
  padding-bottom: ${measure.measure64};
  padding-left: ${measure.measure24};
  padding-right: ${measure.measure24};
`

const DesktopLeft = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: flex-start;
  position: relative;
  flex: 1;
`

const MobileRoot = styled.div`
  ${rootStyle}
  height: 100vh;

  ${media.biggerThan(breakpoints.large)} {
    display: none;
  }
`

const MobileMain = styled.div`
  width: 100%;
  /**
   * Parent of Gatsby Image needs fixed height. 
   * (Mobile header height: 128px) 
   */
  height: calc(100vh - 72px);

  padding-top: ${measure.measure48};
  padding-bottom: ${measure.measure96};
  padding-left: ${measure.measure16};
  padding-right: ${measure.measure16};

  display: flex;
  flex-direction: column;
  justify-content: start;

  & > * + * {
    margin-top: ${measure.measure16};
  }
`

const Title = styled.h1<{ language: Language }>`
  color: ${colors.gray100};
  font-weight: 400;
  margin: 0;
  white-space: nowrap;

  ${media.smallerThan(breakpoints.large)} {
    font-size: ${({ language }) => (language === "es" ? "30px" : "36px")};
    line-height: 1.3;
  }

  ${media.biggerThan(breakpoints.large)} {
    z-index: 1000;
    position: absolute;
    left: 0;

    font-size: 54px;
    line-height: 70px;
  }
`

const DesktopScrollDownButton = styled(ScrollDownButton)`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -${measure.measure64};
  align-self: center;
  z-index: 1000;
`

const MobileScrollDownButton = styled(ScrollDownButton)`
  align-self: center;
`

export const PrimaryHero = () => {
  const language = useLanguage()
  const data = useStaticQuery(query)

  return (
    <>
      <DesktopRoot>
        <DesktopHeader transparent={true} />
        <DesktopMain>
          <DesktopLeft>
            <Title language={language}>
              {i18n[language].title.map(text => (
                <div key={text}>{text}</div>
              ))}
              <DesktopScrollDownButton />
            </Title>
          </DesktopLeft>
          <Image
            fluid={data.heroImage.childImageSharp.fluid}
            style={{
              flex: language === "es" ? 1.2 : 2.2,
              height: "100%",
            }}
          />
        </DesktopMain>
      </DesktopRoot>

      <MobileRoot>
        <MobileHeader />
        <MobileMain>
          <Title language={language}>
            {i18n[language].title.map(text => (
              <div key={text}>{text}</div>
            ))}
          </Title>
          <Image
            fluid={data.heroImage.childImageSharp.fluid}
            style={{
              height: "100%",
            }}
          />
          <MobileScrollDownButton />
        </MobileMain>
      </MobileRoot>
    </>
  )
}

const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero/hero-primary.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
