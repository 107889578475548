import React from "react"
import styled from "styled-components"
import { ContentContainer } from "../components/ContentContainer"
import { breakpoints, measure, media } from "../styles"
import { useLanguage } from "../utils/useLanguage"

const i18n = {
  es: {
    title: "Vídeos",
  },
  en: {
    title: "Videos",
  },
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const H1 = styled.h1`
  margin-bottom: ${measure.measure48};
`

const VideoSection = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;
  grid-gap: ${measure.measure48};
  width: 100%;

  ${media.smallerThan(breakpoints.large)} {
    grid-auto-flow: row;
    grid-auto-rows: 1fr 1fr;
  }
`

const VideoContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;

  &:after {
    padding-top: 65.25%;
    display: block;
    content: "";
  }
`

const IFrame = styled.iframe`
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const Gallery = () => {
  const language = useLanguage()
  return (
    <ContentContainer maxWidth="wide">
      <Root>
        <H1>{i18n[language].title}</H1>
        <VideoSection>
          <VideoContainer>
            <IFrame
              title="The little bird"
              src="https://www.youtube.com/embed/ADza_SxyWOA"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture"
            />
          </VideoContainer>
          <VideoContainer>
            <IFrame
              title="Three fishermen"
              src="https://www.youtube.com/embed/eS1q8Ahcb3A"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture"
            />
          </VideoContainer>
        </VideoSection>
      </Root>
    </ContentContainer>
  )
}
