import React from "react"
import styled from "styled-components"
import { VscArrowDown } from "react-icons/vsc"
import zenscroll from "zenscroll"
import { colors, fontSizes, measure } from "../styles"

const Icon = styled(VscArrowDown)`
  color: ${colors.gray200};
  font-size: ${fontSizes.size36};
`

export const ScrollDownButton = styled.button.attrs({
  children: <Icon />,
  onClick: () => {
    const elem = document.getElementsByTagName("main").item(0)
    if (elem) {
      zenscroll.intoView(elem)
    }
  },
})`
  background: none;
  border: none;
  outline: none;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  height: ${measure.measure48};

  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: translateY(20%);
  }
`
